import { createApp } from 'vue';
import 'dotenv/config';
import App from './App.vue';
import router from './router/index';
import store from './store/index';
import PrimeVue from 'primevue/config';
import Toast, { POSITION } from 'vue-toastification';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'vue-toastification/dist/index.css';

import 'primeicons/primeicons.css';
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/md-light-deeppurple/theme.css';


// // Anychart modules for enable menucontext 
import 'anychart/dist/js/anychart-core.min.js';
import 'anychart/dist/js/anychart-bundle.min.js';
import 'anychart/dist/js/anychart-base.min.js';

import 'anychart/dist/css/anychart-ui.min.css'; 
import 'anychart/dist/js/anychart-ui.min.js'; 

import 'anychart/dist/js/anychart-exports.min.js';



// import the fontawesome core 
import { library } from '@fortawesome/fontawesome-svg-core'
// import font awesome icon component
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

//font awesome Icons 
import { faIndustry } from '@fortawesome/free-solid-svg-icons'
import { faLandmarkDome } from '@fortawesome/free-solid-svg-icons'
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { faSun } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faFileContract } from '@fortawesome/free-solid-svg-icons';
import { faFloppyDisk as farFloppyDisk } from '@fortawesome/free-regular-svg-icons';
// NavBar
import { faUser as farUser } from '@fortawesome/free-regular-svg-icons';



//font awesome ProIcons 
import { faIndustryWindows as falIndustryWindows } from '@fortawesome/pro-light-svg-icons'
import { faLandmarkDome as falLandmarkDome  } from '@fortawesome/pro-light-svg-icons'
import { faMemoCircleInfo as falMemoCircleInfo } from '@fortawesome/pro-light-svg-icons'
import { faFloppyDiskCircleArrowRight as farFloppyDiskCircleArrowRight} from '@fortawesome/pro-regular-svg-icons';
import { faMinimize as farMinimize } from '@fortawesome/pro-regular-svg-icons';
import { faMaximize as farMaximize } from '@fortawesome/pro-regular-svg-icons';

import { faGlobe as faRegularGlobe } from '@fortawesome/pro-regular-svg-icons';
import { faTag as faRegularTag } from '@fortawesome/pro-regular-svg-icons';
import { faLinkSlash } from '@fortawesome/free-solid-svg-icons';
import { faSquarePollHorizontal } from '@fortawesome/pro-light-svg-icons';
import { faSquareT } from '@fortawesome/pro-light-svg-icons';

import { faSquareList } from '@fortawesome/pro-light-svg-icons';

//  NavBar
import { faGear as farGear } from '@fortawesome/pro-regular-svg-icons';
import { faUsersGear as farUsersGears } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightFromBracket as farArrowRightFromBracket } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightToBracket as farArrowRightToBracket } from '@fortawesome/pro-regular-svg-icons';

// TODO UPDATE vueup
// npm update @vueup/vue-quill
// npm update quill
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import Tooltip from 'primevue/tooltip';

library.add( 
  faIndustry, 
  faLandmarkDome, 
  faBook, 
  faFlag, 
  faBuilding, 
  faSun, 
  faCheck,
  faFileContract, 
  falIndustryWindows, 
  falLandmarkDome, 
  falMemoCircleInfo,
  farFloppyDisk,
  farUser,
  farFloppyDiskCircleArrowRight,
  farMinimize,
  farMaximize,
  faRegularGlobe,
  faRegularTag,
  faLinkSlash,
  faSquarePollHorizontal,
  faSquareT,
  faSquareList,
  farGear,
  farUsersGears,
  farArrowRightFromBracket,
  farArrowRightToBracket,
)

const app = createApp(App);

app.directive('tooltip', Tooltip);
app.use(router);
app.use(store);
app.use(PrimeVue);
app.use(Toast, {
  position: POSITION.BOTTOM_RIGHT,
});
// app.component(QuillEditor);
app.component('QuillEditor',QuillEditor);
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app');
