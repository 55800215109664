import axios from 'axios';
import { axiosProvider } from '../../../../shared/config/axios.config';

async function getCompanies() {
  const response = await axiosProvider.get('/companies');
  const companies = response.data;
  return companies;
}

async function getFilteredCompanies(totalDocuments, hasPriority, totalPrograms) {
  const response = await axiosProvider.get(`/companies/filtered?totalDocuments=${totalDocuments}&hasPriority=${hasPriority}&totalPrograms=${totalPrograms}`)
  const companies = response.data;
  return companies;
}

async function getCompanyTypes() {
  const response = await axiosProvider.get('/companies/companyTypes');
  const companies = response.data;
  return companies;
}

async function getCompanyByCompanyId(companyId) {
  const response = await axiosProvider.get(`/companies/${companyId}`);
  const companies = response.data;
  return companies;
}

async function getCompaniesByTopicId(topicId) {
  const response = await axiosProvider.get(`/companies/topic/${topicId}?withtotals=true`);
  const companies = response.data;
  return companies;
}

async function getCompaniesWithTotalsByTopicId(topicId, totals, byTopic){
  
  const response = await axiosProvider.get(`companies/filtered/topic/${topicId}?totals=${totals}&byTopic=${byTopic}`);
  const companies = response.data;
  return companies;
}

async function createCompany(company) {
  const response = await axiosProvider.post('/companies', company);
  const data = response.data;
  return data;
}

async function editCompany(companyId, company) {
  const response = await axiosProvider.put(`/companies/${companyId}`, company);
  const data = response.data;
  return data;
}

async function deleteCompany(companyId) {
  const response = await axiosProvider.delete(`/companies/${companyId}`);
  const data = response.data;
  return data;
}

async function getContractsPerCompany(topicId) {
  const response = await axiosProvider.get(`/companies/topic/${topicId}/contracts`);
  if (!response) {
    return null
  }
  else {
    const contractPerCompany = response.data;
    return contractPerCompany;
  }
}

async function exportToExcelContractPerCompanies(selectedTopic){
  const topicName = selectedTopic.name.split(' ').join('');
  console.log(topicName);
  
  // await axiosProvider.get(`/companies/topic/${selectedTopic.topicId}/export`)
  await axios({
    method: 'get',
    url:process.env.VUE_APP_API_URL +`/companies/topic/${selectedTopic.topicId}/export`,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token-mfkb')}`,
      'Content-Type': 'application/json;'
    },
    responseType: 'blob'
  })

  .then((response)=>{
      const url = URL.createObjectURL(new Blob ([response.data], {
        type: 'application/vnd.ms-excel'
      }))
      const link = document.createElement('a');
      link.href = url; 
      link.setAttribute('download',`ContractPerCompanies-${topicName}.xlsx`)
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
    })
}  

async function getCompaniesTopicDescription(companyId, topicId){
  const response = await axiosProvider.get(`/companies/${companyId}/topic/${topicId}`)
  const data = response.data;
  return data;
}


// SWOT
async function getCompanyAnalysesType(companyId){
  const response = await axiosProvider.get(`/companies/companyAnalysesType?code=SWOT&companyId=${companyId}`)
  const data = response.data
  return data;  
}

async function addCompanyAnalysesItems(SWOTForm){
  const response = await axiosProvider.post(`/companies/companyAnalysesItem`, SWOTForm);
  // console.log(response);
  const data = response.data;
  return data;
}

async function editCompanyAnalysesItems(SWOTForm, companyAnalysesItemId){
  const response = await axiosProvider.put(`/companies/companyAnalysesItem/${companyAnalysesItemId}`, SWOTForm);
  const data = response.data; 
  return data;
}

async function deleteCompanyAnalysesItems(SWOTItemId){
  const response = await axiosProvider.delete(`/companies/companyAnalysesItem/${SWOTItemId}`);
  const data = response.data;
  return data
}

async function changeOrderCompanyAnalysesItem( companyAnalysesItemId, goUp  ){
  const response = await axiosProvider.put(`/companies/companyAnalysesItem/${companyAnalysesItemId}/changeOrder?goUp=${goUp}`);
  const data = response.data; 
  return data
}

export {
  getCompanies,
  getFilteredCompanies,
  getCompanyTypes,
  getCompanyByCompanyId,
  getCompaniesByTopicId,
  getCompaniesWithTotalsByTopicId,
  createCompany,
  editCompany,
  deleteCompany,
  getContractsPerCompany,
  exportToExcelContractPerCompanies,
  getCompaniesTopicDescription,
  getCompanyAnalysesType,
  addCompanyAnalysesItems,
  editCompanyAnalysesItems,
  deleteCompanyAnalysesItems,
  changeOrderCompanyAnalysesItem,
};
