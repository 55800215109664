import { axiosProvider } from '../../../../shared/config/axios.config';
import axios from 'axios';

async function getTopics() {
  const response = await axiosProvider.get('/topics');
  const data = response.data;
  return data;
}

async function getFilteredTopics(totalDocuments , totalPrograms){
  const response = await axiosProvider.get(`/topics/filtered/?totalDocuments=${totalDocuments}&totalPrograms=${totalPrograms}`);
  const data = response.data;
  return data;
}

async function getTopicById(topicId) {
  const response = await axiosProvider.get(`/topics/${topicId}`);
  const data = response.data;
  return data;
}

async function getTopicByCompanyId(companyId) {
  const response = await axiosProvider.get(`/topics/company/${companyId}`)
  const data = response.data;
  return data;
}

async function createTopic(topic) {
  const response = await axiosProvider.post('/topics', topic);
  const data = response.data;
  return data;
}

async function editTopic(topicId, topic) {
  delete topic.topicId;
  const response = await axiosProvider.put(`/topics/${topicId}`, topic);
  const data = response.data;
  return data;
}

async function deleteTopic(topicId) {
  const response = await axiosProvider.delete(`/topics/${topicId}`);
  const data = response.data;
  return data;
}
// outputVersus
async function getOutputVersus(topicId) {
  const response = await axiosProvider.get(`topics/${topicId}/output/versus`)
  const data = response.data;
  return data;
}
async function getOutputValues(topicId, form) {
  const response = await axiosProvider.post(`topics/${topicId}/output/values`, form)
  const data = response.data;
  return data;
}

async function getSingleOutputValues(topicId, form){
  const response = await axiosProvider.post(`topics/${topicId}/output/single/values`, form);
  const data = response.data;
  return data;
}

async function exportToExcelOutputValues(
  topicId, 
  form, 
  topicName, 
  // valueDisplayOption
) {
  await axios({
    method: 'post',
    url: process.env.VUE_APP_API_URL + `/topics/${topicId}/output/values/export`,
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token-mfkb')}`,
      'Content-Type': 'application/json;'
    },
    data: form,
    responseType: 'blob'
  }).then((response) => {
    const url = URL.createObjectURL(new Blob([response.data], {
      type: 'application/vnd.ms-excel'
    }))
    const link = document.createElement('a')
    link.href = url
    // TODO validate valueDisplayOption for backend exports
    link.setAttribute('download', `MarketForecastData_${topicName}_${form.startYear}-${form.endYear}_US$.xlsx`)
    document.body.appendChild(link)
    link.click();

    URL.revokeObjectURL(url);
  });
}

async function unLinkDocumentsFromTopic(topicId, documentsForm){
  const response = await axiosProvider.post(`/topics/${topicId}/UnlinkDocumentsFromTopic`,documentsForm)
  return response.data
}

// scenarios 
async function getScenarioByScenarioId(scenarioId) {
  const response = await axiosProvider.get(`/scenarios/${scenarioId}`)
  const data = response.data;
  return data
}

async function getScenariosByTopicId(topicId) {
  const response = await axiosProvider.get(`/scenarios/topic/${topicId}`);
  const data = response.data;
  return data
}
async function createScenario(scenario) {
  const response = await axiosProvider.post('/scenarios/', scenario)
  const data = response.data
  return data
}
async function editScenario(scenarioId, scenario) {
  const response = await axiosProvider.put(`/scenarios/${scenarioId}`, scenario);
  const data = response.data;
  return data
}
async function deleteScenario(scenarioId) {
  const response = await axiosProvider.delete(`/scenarios/${scenarioId}`);
  const data = response.data;
  return data
}

export { 
  getTopics, 
  getFilteredTopics, 
  getTopicById, 
  getTopicByCompanyId, 
  createTopic, 
  editTopic, 
  deleteTopic, 
  getOutputVersus, 
  getOutputValues, 
  getSingleOutputValues, 
  exportToExcelOutputValues, 
  unLinkDocumentsFromTopic,
  getScenarioByScenarioId, 
  getScenariosByTopicId, 
  createScenario, 
  editScenario, 
  deleteScenario 

};

